import React from 'react';
import PropTypes from 'prop-types';

import formatClassNames from '../../utils/formatClassNames';

import _rowStyles from './row.module.scss';
import _utils from '../../styles/utils.module.scss';

const Row = ({
  children,
  className,
  justify,
  align,
  grow,
  shrink,
  basis,
  noPadding,
  noMargin,
  bgColor,
  ...props
}) => {
  const classes = formatClassNames([
    _utils.flex,
    _utils.flexRow,
    _rowStyles.row,
    Boolean(align) ? _utils[`flexAlign${align}`] : null,
    Boolean(justify) ? _utils[`flexJustify${justify}`] : null,
    Boolean(grow) ? _utils[`flexGrow${grow}`] : null,
    Boolean(shrink) ? _utils[`flexShrink${shrink}`] : null,
    Boolean(basis) ? _utils[`flexBasis${basis}`] : null,
    Boolean(noPadding) ? _utils[`padding0`] : null,
    Boolean(noMargin) ? _utils[`margin0`] : null,
    Boolean(bgColor) ? _utils[`${bgColor}BgColor`] : null,
    className,
  ]);

  return (
    <div className={`${classes}`} {...props}>
      {children}
    </div>
  );
};

Row.propTypes = {
  className: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  grow: PropTypes.string,
  shrink: PropTypes.string,
  basis: PropTypes.string,
  noPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
  bgColor: PropTypes.string,
};

Row.defaultProps = {
  className: '',
  align: '',
  justify: '',
  grow: '',
  shrink: '',
  basis: '',
  noPadding: false,
  noMargin: false,
  bgColor: ''
};

export default Row;
