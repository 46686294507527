import React from 'react';
import PropTypes from 'prop-types';

import formatClassNames from '../../utils/formatClassNames';

import _containerStyles from './container.module.scss';
import _utils from '../../styles/utils.module.scss';

const Container = ({
  children,
  className,
  fluid,
  fullScreen,
  align,
  justify,
  Background,
  ...props
}) => {
  const classes = formatClassNames([
    _containerStyles.container,
    Boolean(align) ? _utils[`flexAlign${align}`] : null,
    Boolean(justify) ? _utils[`flexJustify${justify}`] : null,
    Boolean(fluid) && _containerStyles.fluid,
    Boolean(fullScreen) && _containerStyles.fullScreen,
    Boolean(Background) && _containerStyles.withBackground,
    className,
  ]);

  if (Boolean(Background)) {
    return <Background className={classes}>{children}</Background>;
  }

  return (
    <section className={`${classes}`} {...props}>
      {children}
    </section>
  );
};

Container.propTypes = {
  Background: PropTypes.func,
  fluid: PropTypes.bool,
  fullScreen: PropTypes.bool,
  align: PropTypes.string,
  justify: PropTypes.string,
};

export default Container;
