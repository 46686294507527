import React from 'react';

import _themeArtDirectionStyles from './art-direction.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

const Overlay = () => {
  return (
    <div className={`${_utils.overlay} ${_themeArtDirectionStyles.overlay}`} />
  );
};

export default Overlay;
