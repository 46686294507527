import React from 'react';
import PropTypes from 'prop-types';

import formatClassNames from '../../utils/formatClassNames';

import _utils from '../../styles/utils.module.scss';

const Col = ({
  children,
  className,
  justify,
  align,
  grow,
  shrink,
  basis,
  ...props
}) => {
  const classes = formatClassNames([
    _utils.flex,
    _utils.flexColumn,
    Boolean(align) ? _utils[`flexAlign${align}`] : null,
    Boolean(justify) ? _utils[`flexJustify${justify}`] : null,
    Boolean(grow) ? _utils[`flexGrow${grow}`] : null,
    Boolean(shrink) ? _utils[`flexShrink${shrink}`] : null,
    Boolean(basis) ? _utils[`flexBasis${basis}`] : null,
    className,
  ]);

  return (
    <div className={`${classes}`} {...props}>
      {children}
    </div>
  );
};

Col.propTypes = {
  className: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  grow: PropTypes.string,
  shrink: PropTypes.string,
  basis: PropTypes.string,
};

Col.defaultProps = {
  className: '',
  align: '',
  justify: '',
  grow: '',
  shrink: '',
  basis: '',
};

export default Col;
